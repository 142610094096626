<template>
  <div id="footer-wrapper">
    <div>
      <img id="logo" src="../assets/Compadre.png" />
      <p style="margin: 0; margin-left: 10px;">© Compadre 2024. All Rights Reserved.</p>
    </div>
    <div class="footer-links">
      <router-link class="footer-link" to="/privacy_policy">Privacy Policy</router-link>
      <router-link class="footer-link" to="/terms_of_service">Terms of Service</router-link>
    </div>
  </div>
</template>

<script setup lang="ts">

</script>

<style>

#footer-wrapper {
  display: flex;
  height: 100px;
  width: 70%;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin-bottom: 30px;
}

.footer-link {
  color: #464255;
  text-decoration: none;
}

.footer-links {
  display: flex;
  gap: 20px;
  justify-content: end;
}

@media (max-width: 1105px) {
  #footer-wrapper {
    width: 90%;
  }
}
@media (max-width: 722px) {
  #footer-wrapper {
    zoom: 80%;
  }
  .footer-links {
    flex-direction: column;
    gap: 10px;
  }
}
@media (max-width: 386px) {
  #footer-wrapper {
    zoom: 70%;
    height: 50px;
  }
}
</style>