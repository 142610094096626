import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import App from '../App.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import Home from '../Home.vue';
import TermsOfService from '@/views/TermsOfService.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/privacy_policy',
    name: 'privacy policy',
    component: PrivacyPolicy
  },
  {
    path: '/terms_of_service',
    name: 'terms of service',
    component: TermsOfService
  },

]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPositon) {
    return { top: 0 }
  }
})

export default router
